<template>
  <div class="d-flex flex-row align-center">
    <v-text-field
      :hide-details="false"
      :label="$t('t.Values')"
      :type="textFieldType"
      class="value"
      ref="input"
      v-model="computedFrom"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :rules="rules"
    />
    <span class="mx-4">{{$t('t.And')}}</span>
    <v-text-field
      :hide-details="false"
      :label="$t('t.Values')"
      :type="textFieldType"
      class="value"
      ref="input"
      v-model="computedTo"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :rules="rules"
    />
  </div>
</template>
<script>
export default {
  components: {
  },
  computed: {
    computedFrom: {
      get () { return this.from },
      set (v) {
        this.from = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedTo: {
      get () { return this.to },
      set (v) {
        this.to = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    textFieldType () {
      return this.isNumber ? 'number' : 'text'
    }
  },
  data () {
    return {
      from: null,
      to: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = [this.from, this.to]

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    dense: Boolean,
    disabled: Boolean,
    document: Array,
    isNumber: Boolean,
    readonly: Boolean,
    rules: Array,
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        // Enforcing 0 seem to broke things, UI speaking
        this.from = v?.[0] ? this.lodash.cloneDeep(v[0]) : null // (this.isNumber ? 0 : 'null')
        this.to = v?.[1] ? this.lodash.cloneDeep(v[1]) : null // (this.isNumber ? 0 : 'null')
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
